import React, { useEffect, useRef, useState, useCallback } from 'react';
import './BreakoutGame.css';

const BreakoutGame = ({ onGameComplete }) => {
  const canvasRef = useRef(null);
  const gameLoopRef = useRef(null);
  const [score, setScore] = useState(0);
  const [gameOver, setGameOver] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [gameStarted, setGameStarted] = useState(false);

  const initializeGame = useCallback(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    
    // Canvas setup
    const GAME_SIZE = Math.min(canvas.parentElement.offsetWidth, 400);
    canvas.width = GAME_SIZE;
    canvas.height = GAME_SIZE;
    
    // Game constants
    const PADDLE_HEIGHT = 10;
    const PADDLE_WIDTH = GAME_SIZE / 5;
    const BALL_RADIUS = 6;
    const BRICK_ROWS = 5;
    const BRICK_COLS = 8;
    const BRICK_HEIGHT = 20;
    const BRICK_WIDTH = (GAME_SIZE - 40) / BRICK_COLS;
    
    // Game state
    let paddleX = (GAME_SIZE - PADDLE_WIDTH) / 2;
    let ballX = GAME_SIZE / 2;
    let ballY = GAME_SIZE - 30;
    let ballSpeedX = 3;
    let ballSpeedY = -3;
    let currentScore = 0;
    
    // Create bricks
    const bricks = Array(BRICK_ROWS).fill().map((_, row) => 
      Array(BRICK_COLS).fill().map((_, col) => ({
        x: col * BRICK_WIDTH + 20,
        y: row * BRICK_HEIGHT + 30,
        status: 1
      }))
    );

    const drawBall = () => {
      ctx.beginPath();
      ctx.arc(ballX, ballY, BALL_RADIUS, 0, Math.PI * 2);
      ctx.fillStyle = '#00ff00';
      ctx.fill();
      ctx.closePath();
    };

    const drawPaddle = () => {
      ctx.beginPath();
      ctx.rect(paddleX, GAME_SIZE - PADDLE_HEIGHT, PADDLE_WIDTH, PADDLE_HEIGHT);
      ctx.fillStyle = '#00ff00';
      ctx.fill();
      ctx.closePath();
    };

    const drawBricks = () => {
      bricks.forEach((row, rowIndex) => {
        row.forEach(brick => {
          if (brick.status === 1) {
            ctx.beginPath();
            ctx.rect(brick.x, brick.y, BRICK_WIDTH - 4, BRICK_HEIGHT - 4);
            ctx.fillStyle = `hsl(${rowIndex * 30}, 100%, 50%)`;
            ctx.fill();
            ctx.closePath();
          }
        });
      });
    };

    const checkCollisions = () => {
      // Ball-wall collisions
      if (ballX + ballSpeedX > GAME_SIZE - BALL_RADIUS || ballX + ballSpeedX < BALL_RADIUS) {
        ballSpeedX = -ballSpeedX;
      }
      
      if (ballY + ballSpeedY < BALL_RADIUS) {
        ballSpeedY = -ballSpeedY;
      }
      
      // Ball-paddle collision
      if (ballY + ballSpeedY > GAME_SIZE - BALL_RADIUS - PADDLE_HEIGHT) {
        if (ballX > paddleX && ballX < paddleX + PADDLE_WIDTH) {
          ballSpeedY = -ballSpeedY;
          // Add controlled randomness to x direction
          const paddleCenter = paddleX + PADDLE_WIDTH / 2;
          const hitPosition = (ballX - paddleCenter) / (PADDLE_WIDTH / 2);
          ballSpeedX = hitPosition * 5;
        } else if (ballY > GAME_SIZE - BALL_RADIUS) {
          endGame();
          return false;
        }
      }
      
      // Ball-brick collisions
      for (let row = 0; row < BRICK_ROWS; row++) {
        for (let col = 0; col < BRICK_COLS; col++) {
          const brick = bricks[row][col];
          if (brick.status === 1) {
            if (ballX > brick.x && ballX < brick.x + BRICK_WIDTH &&
                ballY > brick.y && ballY < brick.y + BRICK_HEIGHT) {
              ballSpeedY = -ballSpeedY;
              brick.status = 0;
              currentScore += 10;
              setScore(currentScore);
              
              // Check if all bricks are destroyed
              if (bricks.every(row => row.every(brick => brick.status === 0))) {
                endGame();
                return false;
              }
            }
          }
        }
      }
      return true;
    };

    const movePaddle = (e) => {
      if (!isPaused && gameStarted) {
        const canvasRect = canvas.getBoundingClientRect();
        let relativeX;
        
        // Handle both mouse and touch events
        if (e.type === 'touchmove') {
          // Prevent scrolling while playing
          e.preventDefault();
          relativeX = e.touches[0].clientX - canvasRect.left;
        } else {
          relativeX = e.clientX - canvasRect.left;
        }
        
        paddleX = Math.max(0, Math.min(GAME_SIZE - PADDLE_WIDTH, relativeX - PADDLE_WIDTH / 2));
      }
    };

    const endGame = () => {
      if (gameLoopRef.current) {
        cancelAnimationFrame(gameLoopRef.current);
        gameLoopRef.current = null;
      }
      setGameOver(true);
      onGameComplete(currentScore);
    };

    const gameLoop = () => {
      if (!isPaused && gameStarted && !gameOver) {
        ctx.clearRect(0, 0, GAME_SIZE, GAME_SIZE);
        
        drawBricks();
        drawBall();
        drawPaddle();
        
        if (checkCollisions()) {
          ballX += ballSpeedX;
          ballY += ballSpeedY;
          gameLoopRef.current = requestAnimationFrame(gameLoop);
        }
      }
    };

    // Event listeners
    canvas.addEventListener('mousemove', movePaddle);
    canvas.addEventListener('touchmove', movePaddle, { passive: false });
    gameLoop();

    return () => {
      canvas.removeEventListener('mousemove', movePaddle);
      canvas.removeEventListener('touchmove', movePaddle);
      if (gameLoopRef.current) {
        cancelAnimationFrame(gameLoopRef.current);
      }
    };
  }, [isPaused, gameStarted, gameOver, onGameComplete]);

  const startNewGame = () => {
    setScore(0);
    setGameOver(false);
    setIsPaused(false);
    setGameStarted(true);
    if (gameLoopRef.current) {
      cancelAnimationFrame(gameLoopRef.current);
    }
    initializeGame();
  };

  useEffect(() => {
    initializeGame();
    return () => {
      if (gameLoopRef.current) {
        cancelAnimationFrame(gameLoopRef.current);
      }
    };
  }, [initializeGame]);

  return (
    <div className="breakout-game">
      <h3>Breakout</h3>
      <p>Score: {score}</p>
      <button 
        className="pause-button" 
        onClick={() => setIsPaused(!isPaused)}
        disabled={!gameStarted || gameOver}
      >
        {isPaused ? 'Resume' : 'Pause'}
      </button>
      <div className="canvas-container">
        <canvas ref={canvasRef}></canvas>
        {gameOver && (
          <div className="game-over-overlay">
            <h4>Game Over!</h4>
            <p>Final Score: {score}</p>
            <button onClick={startNewGame}>Play Again</button>
          </div>
        )}
        {!gameStarted && !gameOver && (
          <div className="start-overlay">
            <button onClick={startNewGame}>Start Game</button>
          </div>
        )}
      </div>
    </div>
  );
};

export default BreakoutGame;
