const API_BASE_URL = 'https://backend-feed-31c325a33e0b.herokuapp.com';

export const submitGameScore = async (walletAddress, gameType, score) => {
  try {
    const response = await fetch(`${API_BASE_URL}/api/earnings/game-complete`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ walletAddress, gameType, score }),
    });
    
    if (!response.ok) {
      const errorData = await response.json().catch(() => ({}));
      console.error('API Error:', {
        status: response.status,
        statusText: response.statusText,
        errorData
      });
      throw new Error(`Failed to submit score: ${response.status}`);
    }
    
    return response.json();
  } catch (error) {
    console.error('Submit score error:', error);
    throw error;
  }
};

export const claimEarnings = async (walletAddress) => {
  try {
    const response = await fetch(`${API_BASE_URL}/api/earnings/claim`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ walletAddress }),
    });
    
    if (!response.ok) {
      const errorData = await response.json().catch(() => ({}));
      console.error('API Error:', {
        status: response.status,
        statusText: response.statusText,
        errorData
      });
      throw new Error(`Failed to claim earnings: ${response.status}`);
    }
    
    return response.json();
  } catch (error) {
    console.error('Claim earnings error:', error);
    throw error;
  }
}; 