import React, { useState, useEffect, useCallback } from 'react';
import './WhackAMoleGame.css';

const WhackAMoleGame = ({ onGameComplete }) => {
  const [score, setScore] = useState(0);
  const [timeLeft, setTimeLeft] = useState(60);
  const [moles, setMoles] = useState(Array(9).fill(false));
  const [isPlaying, setIsPlaying] = useState(false);
  const [gameEndTime, setGameEndTime] = useState(null);

  const spawnMole = useCallback(() => {
    const spawnTwo = Math.random() < 0.15;
    
    const firstHole = Math.floor(Math.random() * 9);
    
    let secondHole;
    if (spawnTwo) {
      do {
        secondHole = Math.floor(Math.random() * 9);
      } while (secondHole === firstHole);
    }

    const duration = Math.random() * 700 + 800;

    setMoles(current => {
      const newMoles = [...current];
      newMoles[firstHole] = true;
      if (spawnTwo) newMoles[secondHole] = true;
      return newMoles;
    });

    setTimeout(() => {
      setMoles(prev => {
        const updated = [...prev];
        updated[firstHole] = false;
        if (spawnTwo) updated[secondHole] = false;
        return updated;
      });
    }, duration);
  }, []);

  const startGame = () => {
    setIsPlaying(true);
    setScore(0);
    setTimeLeft(60);
    setMoles(Array(9).fill(false));
    setGameEndTime(Date.now() + 60000);
  };

  const whackMole = (index) => {
    if (!moles[index] || !isPlaying) return;
    setScore(prev => prev + 1);
    setMoles(prev => {
      const newMoles = [...prev];
      newMoles[index] = false;
      return newMoles;
    });
  };

  useEffect(() => {
    let timer;
    let moleTimer;

    if (isPlaying) {
      timer = setInterval(() => {
        const now = Date.now();
        const remaining = Math.ceil((gameEndTime - now) / 1000);
        
        if (remaining <= 0) {
          clearInterval(timer);
          clearInterval(moleTimer);
          setIsPlaying(false);
          setTimeLeft(0);
          onGameComplete(score);
        } else {
          setTimeLeft(remaining);
        }
      }, 100);

      const spawnMoles = () => {
        spawnMole();
        const nextSpawn = Math.random() * 1000 + 1000;
        moleTimer = setTimeout(spawnMoles, nextSpawn);
      };

      spawnMoles();
    }

    return () => {
      clearInterval(timer);
      clearTimeout(moleTimer);
    };
  }, [isPlaying, score, onGameComplete, spawnMole, gameEndTime]);

  return (
    <div className="whackamole-game">
      <div className="game-info">
        <h3>Whack-A-Mole</h3>
        <p>Score: {score}</p>
        <p>Time: {timeLeft}s</p>
        {!isPlaying && <button onClick={startGame}>Start Game</button>}
      </div>
      
      <div className="mole-grid">
        {moles.map((isMoleVisible, index) => (
          <div 
            key={index} 
            className={`mole-hole ${isMoleVisible ? 'mole-up' : ''}`}
            onClick={() => whackMole(index)}
          >
            {isMoleVisible && <div className="mole" />}
          </div>
        ))}
      </div>
    </div>
  );
};

export default WhackAMoleGame; 