import React, { useState, useEffect } from 'react';
import './MemoryMatchGame.css';

const MemoryMatchGame = ({ onGameComplete }) => {
  const [cards, setCards] = useState([]);
  const [flipped, setFlipped] = useState([]);
  const [matched, setMatched] = useState([]);
  const [score, setScore] = useState(0);
  const [moves, setMoves] = useState(0);
  const [isCompleted, setIsCompleted] = useState(false);

  const cardImages = [
    '🐶', '🐱', '🐭', '🐹', '🐰', '🦊', '🐻', '🐼'
  ];

  useEffect(() => {
    initializeGame();
  }, []);

  const initializeGame = () => {
    const duplicatedCards = [...cardImages, ...cardImages];
    const shuffledCards = duplicatedCards
      .sort(() => Math.random() - 0.5)
      .map((symbol, index) => ({ id: index, symbol, isFlipped: false }));
    setCards(shuffledCards);
    setFlipped([]);
    setMatched([]);
    setScore(0);
    setMoves(0);
    setIsCompleted(false);
  };

  const handleCardClick = (cardId) => {
    if (flipped.length === 2 || flipped.includes(cardId) || matched.includes(cardId)) return;

    const newFlipped = [...flipped, cardId];
    setFlipped(newFlipped);

    if (newFlipped.length === 2) {
      setMoves(moves + 1);
      const [firstId, secondId] = newFlipped;
      if (cards[firstId].symbol === cards[secondId].symbol) {
        setMatched([...matched, firstId, secondId]);
        setScore(score + 10);
        setFlipped([]);
      } else {
        setTimeout(() => setFlipped([]), 1000);
      }
    }
  };

  useEffect(() => {
    const checkGameComplete = () => {
      if (matched.length === cards.length && cards.length > 0 && !isCompleted) {
        setIsCompleted(true);
        const finalScore = score - moves;
        onGameComplete(Math.max(0, finalScore));
      }
    };

    checkGameComplete();
  }, [matched]);

  return (
    <div className="memory-game">
      <div className="game-info">
        <p>Score: {score}</p>
        <p>Moves: {moves}</p>
        <button onClick={initializeGame}>New Game</button>
      </div>
      <div className="card-grid">
        {cards.map((card, index) => (
          <div
            key={card.id}
            className={`card ${(flipped.includes(index) || matched.includes(index)) ? 'flipped' : ''}`}
            onClick={() => handleCardClick(index)}
          >
            <div className="card-inner">
              <div className="card-front">?</div>
              <div className="card-back">{card.symbol}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MemoryMatchGame; 