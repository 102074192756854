import React, { useEffect, useRef, useState } from 'react';
import './SnakeGame.css';

const SnakeGame = ({ onGameComplete }) => {
  const canvasRef = useRef(null);
  const [score, setScore] = useState(0);
  const [gameOver, setGameOver] = useState(false);
  const [lastTouchX, setLastTouchX] = useState(null);
  const [lastTouchY, setLastTouchY] = useState(null);
  
  const gameLoopRef = useRef(null);
  const directionRef = useRef('right');
  const snakeRef = useRef([{ x: 10, y: 10 }]);
  const foodRef = useRef({ x: 15, y: 15 });
  const currentScoreRef = useRef(0);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    
    const GRID_SIZE = 20;
    const GAME_SIZE = 400;
    canvas.width = GAME_SIZE;
    canvas.height = GAME_SIZE;

    const drawSnake = () => {
      ctx.fillStyle = '#00ff00';
      snakeRef.current.forEach(segment => {
        ctx.fillRect(
          segment.x * GRID_SIZE,
          segment.y * GRID_SIZE,
          GRID_SIZE - 2,
          GRID_SIZE - 2
        );
      });
    };

    const drawFood = () => {
      ctx.fillStyle = '#ff0000';
      ctx.fillRect(
        foodRef.current.x * GRID_SIZE,
        foodRef.current.y * GRID_SIZE,
        GRID_SIZE - 2,
        GRID_SIZE - 2
      );
    };

    const moveSnake = () => {
      const head = { ...snakeRef.current[0] };

      switch(directionRef.current) {
        case 'up': head.y--; break;
        case 'down': head.y++; break;
        case 'left': head.x--; break;
        case 'right': head.x++; break;
        default: break;
      }

      if (head.x < 0 || head.x >= GAME_SIZE / GRID_SIZE ||
          head.y < 0 || head.y >= GAME_SIZE / GRID_SIZE ||
          snakeRef.current.some(segment => segment.x === head.x && segment.y === head.y)) {
        endGame();
        return;
      }

      snakeRef.current.unshift(head);

      if (head.x === foodRef.current.x && head.y === foodRef.current.y) {
        currentScoreRef.current += 10;
        setScore(currentScoreRef.current);
        foodRef.current = {
          x: Math.floor(Math.random() * (GAME_SIZE / GRID_SIZE)),
          y: Math.floor(Math.random() * (GAME_SIZE / GRID_SIZE))
        };
      } else {
        snakeRef.current.pop();
      }
    };

    const resetGame = () => {
      snakeRef.current = [{ x: 10, y: 10 }];
      foodRef.current = { x: 15, y: 15 };
      directionRef.current = 'right';
      currentScoreRef.current = 0;
      setScore(0);
      setGameOver(false);
      if (!gameLoopRef.current) {
        gameLoopRef.current = setInterval(gameStep, 150);
      }
    };

    const handleKeyPress = (e) => {
      e.preventDefault();
      switch(e.key) {
        case 'ArrowUp': if (directionRef.current !== 'down') directionRef.current = 'up'; break;
        case 'ArrowDown': if (directionRef.current !== 'up') directionRef.current = 'down'; break;
        case 'ArrowLeft': if (directionRef.current !== 'right') directionRef.current = 'left'; break;
        case 'ArrowRight': if (directionRef.current !== 'left') directionRef.current = 'right'; break;
        default: break;
      }
    };

    const endGame = () => {
      clearInterval(gameLoopRef.current);
      gameLoopRef.current = null;
      setGameOver(true);
      onGameComplete(currentScoreRef.current);
      drawGameOver();
    };

    const handleTouchStart = (e) => {
      const touch = e.touches[0];
      setLastTouchX(touch.clientX);
      setLastTouchY(touch.clientY);
    };

    const handleTouchMove = (e) => {
      e.preventDefault();
      if (lastTouchX === null || lastTouchY === null) return;
      
      const touch = e.touches[0];
      const currentX = touch.clientX;
      const currentY = touch.clientY;
      const diffX = currentX - lastTouchX;
      const diffY = currentY - lastTouchY;
      
      // Determine if the swipe is more horizontal or vertical
      if (Math.abs(diffX) > Math.abs(diffY)) {
        // Horizontal swipe
        if (diffX > 10 && directionRef.current !== 'left') {
          directionRef.current = 'right';
        } else if (diffX < -10 && directionRef.current !== 'right') {
          directionRef.current = 'left';
        }
      } else {
        // Vertical swipe
        if (diffY > 10 && directionRef.current !== 'up') {
          directionRef.current = 'down';
        } else if (diffY < -10 && directionRef.current !== 'down') {
          directionRef.current = 'up';
        }
      }
      
      setLastTouchX(currentX);
      setLastTouchY(currentY);
    };

    const handleTouchEnd = () => {
      setLastTouchX(null);
      setLastTouchY(null);
    };

    canvas.addEventListener('touchstart', handleTouchStart);
    canvas.addEventListener('touchmove', handleTouchMove);
    canvas.addEventListener('touchend', handleTouchEnd);

    const gameStep = () => {
      ctx.clearRect(0, 0, GAME_SIZE, GAME_SIZE);
      moveSnake();
      drawSnake();
      drawFood();
    };

    document.addEventListener('keydown', handleKeyPress);
    gameLoopRef.current = setInterval(gameStep, 150);

    const drawGameOver = () => {
      ctx.fillStyle = 'rgba(0, 0, 0, 0.8)';
      ctx.fillRect(GAME_SIZE/4, GAME_SIZE/3, GAME_SIZE/2, GAME_SIZE/3);
      
      ctx.fillStyle = '#ffffff';
      ctx.font = '24px Arial';
      ctx.textAlign = 'center';
      ctx.fillText('Game Over!', GAME_SIZE/2, GAME_SIZE/2 - 20);
      ctx.fillText(`Score: ${currentScoreRef.current}`, GAME_SIZE/2, GAME_SIZE/2 + 10);
      
      // Draw play again button
      ctx.fillStyle = '#4CAF50';
      ctx.fillRect(GAME_SIZE/3, GAME_SIZE/2 + 30, GAME_SIZE/3, 40);
      ctx.fillStyle = '#ffffff';
      ctx.fillText('Play Again', GAME_SIZE/2, GAME_SIZE/2 + 55);
    };

    const handleCanvasClick = (e) => {
      if (!gameOver) return;
      
      const rect = canvas.getBoundingClientRect();
      const x = e.clientX - rect.left;
      const y = e.clientY - rect.top;
      
      // Check if click is within Play Again button
      if (x >= GAME_SIZE/3 && x <= GAME_SIZE*2/3 &&
          y >= GAME_SIZE/2 + 30 && y <= GAME_SIZE/2 + 70) {
        resetGame();
      }
    };

    canvas.addEventListener('click', handleCanvasClick);

    // Initial game start
    resetGame();

    return () => {
      document.removeEventListener('keydown', handleKeyPress);
      canvas.removeEventListener('touchstart', handleTouchStart);
      canvas.removeEventListener('touchmove', handleTouchMove);
      canvas.removeEventListener('touchend', handleTouchEnd);
      canvas.removeEventListener('click', handleCanvasClick);
      clearInterval(gameLoopRef.current);
    };
  }, []);

  return (
    <div className="snake-game">
      <h3>Snake Game</h3>
      <p>Score: {score}</p>
      <canvas ref={canvasRef}></canvas>
    </div>
  );
};

export default SnakeGame;
