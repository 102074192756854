import React, { useState, useCallback } from 'react';
import SnakeGame from './games/SnakeGame';
import BreakoutGame from './games/BreakoutGame';
import WhackAMoleGame from './games/WhackAMoleGame';
import MemoryMatchGame from './games/MemoryMatchGame';
import { submitGameScore, claimEarnings } from '../utils/api';
import './GamesSection.css';
import { ethers } from 'ethers';
import { claimEarningsOnChain } from '../utils/contractInteraction';

const GamesSection = ({ account }) => {
  const [selectedGame, setSelectedGame] = useState(null);
  const [earnings, setEarnings] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const games = [
    { id: 'breakout', name: 'Breakout' },
    { id: 'whackamole', name: 'Whack-A-Mole' },
    { id: 'memory', name: 'Memory Match' },
    { id: 'snake', name: 'Snake Game' },
  ];

  const handleGameComplete = useCallback(async (score, gameType) => {
    if (!account) {
      setError('Please connect your wallet first');
      return;
    }

    try {
      setIsLoading(true);
      // Validate inputs
      if (!gameType || typeof score !== 'number') {
        throw new Error('Invalid game data');
      }
      
      console.log('Submitting score:', {
        account,
        gameType,
        score,
        accountType: typeof account,
        scoreType: typeof score
      });
      
      const result = await submitGameScore(account, gameType, score);
      setEarnings(result.totalEarnings || 0);
    } catch (err) {
      setError(err.message || 'Failed to submit score');
      console.error('Game complete error:', err);
    } finally {
      setIsLoading(false);
    }
  }, [account]);

  const handleClaimEarnings = async () => {
    if (!account) {
      setError('Please connect your wallet first');
      return;
    }
    
    try {
      setIsLoading(true);
      
      // Get signed data from backend
      const claimData = await claimEarnings(account);
      
      console.log('Claim data received:', {
        earnings: claimData.earnings,
        signature: claimData.signature,
        earningsType: typeof claimData.earnings,
        signatureType: typeof claimData.signature,
        signatureLength: claimData.signature.length
      });
      
      // Get signer from web3 provider (assuming you're using ethers)
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      
      // Call smart contract
      const receipt = await claimEarningsOnChain(
        signer,
        claimData.earnings,
        claimData.signature
      );
      
      console.log('Claim transaction successful:', receipt.transactionHash);
      setEarnings(0);
      
    } catch (err) {
      setError(err.message || 'Failed to claim earnings');
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  const renderGame = () => {
    switch(selectedGame) {
      case 'snake':
        return <SnakeGame onGameComplete={(score) => handleGameComplete(score, 'snake')} />;
      case 'breakout':
        return <BreakoutGame onGameComplete={(score) => handleGameComplete(score, 'breakout')} />;
      case 'whackamole':
        return <WhackAMoleGame onGameComplete={(score) => handleGameComplete(score, 'whackamole')} />;
      case 'memory':
        return <MemoryMatchGame onGameComplete={(score) => handleGameComplete(score, 'memory')} />;
      default:
        return <p>Select a game to play!</p>;
    }
  };

  return (
    <div className="games-section">
      <h2>Play Games to Earn USD</h2>
      {error && <p className="error-message">{error}</p>}
      <p>Total Earnings: ${earnings.toFixed(10)}</p>
      
      <div className="game-selector">
        <select 
          value={selectedGame || ''} 
          onChange={(e) => setSelectedGame(e.target.value)}
          className="game-dropdown"
          disabled={isLoading}
        >
          <option value="">Select a Game</option>
          {games.map(game => (
            <option key={game.id} value={game.id}>
              {game.name}
            </option>
          ))}
        </select>
      </div>

      <div className="game-container">
        {renderGame()}
        {earnings > 0 && (
          <button 
            className="claim-earnings-button"
            onClick={handleClaimEarnings}
            disabled={isLoading}
          >
            {isLoading ? 'Processing...' : 'Claim Earnings'}
          </button>
        )}
      </div>
    </div>
  );
};

export default GamesSection;
