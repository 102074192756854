import { ethers } from 'ethers';
import GameEarningsABI from '../contracts/GameEarnings.json';

const CONTRACT_ADDRESS = '0xcf9c39751ae1f93f4bdf0a01f157824200793e0c';
const USDC_ADDRESS = '0xaf88d065e77c8cC2239327C5EDb3A432268e5831'; // Replace with the actual USDC token address

export const claimEarningsOnChain = async (signer, amount, signature) => {
    const contract = new ethers.Contract(
        CONTRACT_ADDRESS,
        GameEarningsABI,
        signer
    );

    try {
        const cleanSignature = signature.replace('_', '');
        const formattedSignature = cleanSignature.startsWith('0x') ? cleanSignature : `0x${cleanSignature}`;
        
        const fixedAmount = Number(amount).toFixed(6);
        const parsedAmount = ethers.utils.parseUnits(fixedAmount, 6);
        
        const signerAddress = await signer.getAddress();
        
        console.log('Detailed claim attempt:', {
            signerAddress,
            amount: fixedAmount,
            parsedAmount: parsedAmount.toString(),
            signatureLength: formattedSignature.length,
            signature: formattedSignature,
            contractAddress: CONTRACT_ADDRESS
        });

        try {
            const gasEstimate = await contract.estimateGas.claimEarnings(
                parsedAmount,
                formattedSignature
            );
            console.log('Gas estimate:', gasEstimate.toString());
        } catch (estimateError) {
            console.error('Gas estimation failed:', estimateError);
        }

        const tx = await contract.claimEarnings(
            parsedAmount,
            formattedSignature,
            {
                gasLimit: 300000 // Increased gas limit
            }
        );
        
        return await tx.wait();
    } catch (error) {
        console.error('Contract interaction failed:', error);
        throw error;
    }
};

export const checkContractBalance = async (provider) => {
    const usdcContract = new ethers.Contract(
        USDC_ADDRESS,
        ['function balanceOf(address) view returns (uint256)'],
        provider
    );
    
    const balance = await usdcContract.balanceOf(CONTRACT_ADDRESS);
    console.log('Contract USDC Balance:', ethers.utils.formatUnits(balance, 6));
    return balance;
}; 