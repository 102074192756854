import React, { useState, useEffect, useRef, useCallback } from 'react';
import Web3 from 'web3';
import TermsModal from './components/TermsModal';
import WalletConnection from './components/WalletConnection';
import DepositForm from './components/DepositForm';
import APRCalculator from './components/APRCalculator';
import EarningsDisplay from './components/EarningsDisplay';
import LiquidateEquity from './components/LiquidateEquity';
import LiquidationPopup from './components/LiquidationPopup';
import TransactionHistory from './components/TransactionHistory';
import { FaDiscord, FaTwitter, FaTelegram, FaMoon, FaSun, FaQuestionCircle } from 'react-icons/fa';
import './App.css';
import axios from 'axios';
import { debounce } from 'lodash';
import DarkModeToggle from './components/DarkModeToggle';
import GamesSection from './components/GamesSection';

const CONTRACT_ADDRESS = '0x9EB0dE5217dA65cB562902c4513aA418a51d14ED'; // Replace with your actual contract address
const PRESET_WALLET_ADDRESS = '0x42c348e3692FBb84c3B032cEBA6968C23F2aC213'; // Replace with your actual wallet address
const ADMIN_ADDRESS = '0xb3913E8c814A86F04B3AeA1720812A69b2Adcb48'; // Replace with the actual admin address

// Axios configuration
const API = axios.create({
  baseURL: 'https://apestar-project-79587bd37c77.herokuapp.com',
  withCredentials: true
});

// Add this component definition at the top of your file, outside of the App component
const ReferralInfo = ({ referralCode, referralEarnings }) => {
  const referralLink = `${window.location.origin}?ref=${referralCode}`;

  return (
    <div className="referral-info">
      <h3>Referral Program</h3>
      <p>
        Your Referral Link: 
        <input 
          type="text" 
          value={referralLink} 
          readOnly 
          onClick={(e) => e.target.select()}
        />
        <button onClick={() => navigator.clipboard.writeText(referralLink)}>
          Copy
        </button>
      </p>
      <p>
        Referral Earnings: ${referralEarnings.toFixed(2)}
        <span className="tooltip">
          <FaQuestionCircle />
          <span className="tooltiptext">Earn 3.5% on referrals over 365 days</span>
        </span>
      </p>
    </div>
  );
};

const toSafeNumber = (value) => {
  if (typeof value === 'string') return parseFloat(value);
  if (typeof value === 'number') return value;
  throw new Error(`Cannot convert ${typeof value} to number`);
};

function App() {
  const [isTermsAccepted, setIsTermsAccepted] = useState(false);
  const [web3, setWeb3] = useState(null);
  const [account, setAccount] = useState(null);
  const [balance, setBalance] = useState(0);
  const [apr, setApr] = useState(50);
  const [earningsUSD, setEarningsUSD] = useState(0);
  const [depositAmountUSD, setDepositAmountUSD] = useState(0);
  const [depositTimestamp, setDepositTimestamp] = useState(null);
  const [txHistory, setTxHistory] = useState(() => {
    const savedHistory = localStorage.getItem('txHistory');
    return savedHistory ? JSON.parse(savedHistory) : [];
  });
  const [lastDepositDate, setLastDepositDate] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [showLiquidationPopup, setShowLiquidationPopup] = useState(false);
  const [liquidationCooldown, setLiquidationCooldown] = useState(null);
  const [remainingCooldownTime, setRemainingCooldownTime] = useState(null);
  const [network, setNetwork] = useState('ethereum');
  const [darkMode, setDarkMode] = useState(false);
  const [ethPrice, setEthPrice] = useState(0);
  const [referralCode, setReferralCode] = useState('');
  const [referralEarnings, setReferralEarnings] = useState(0);
  const [showGames, setShowGames] = useState(false);
  
  const lastCalculationTimestamp = useRef(null);
  const lastCalculatedEarnings = useRef(0);

  const saveUserData = useCallback(async (userData) => {
    if (account) {
      try {
        console.log('Attempting to save user data:', userData);
        const response = await API.patch(`/users/${account}`, userData);
        if (response.data.success) {
          console.log('User data saved successfully:', response.data.data);
        } else {
          throw new Error(response.data.message || 'Failed to save user data');
        }
      } catch (error) {
        console.error('Error saving user data:', error);
        if (error.response) {
          console.error('Response data:', error.response.data);
          console.error('Response status:', error.response.status);
        }
        alert(`Failed to save user data: ${error.message}. Please try again.`);
      }
    }
  }, [account]);

  const debouncedSaveUserData = useCallback(
    debounce((userData) => {
      saveUserData(userData);
    }, 5000),
    [saveUserData]
  );

  const fetchUserData = useCallback(async (address) => {
    try {
      const response = await API.get(`/users/${address}`);
      const userData = response.data;
      setDepositAmountUSD(userData.depositAmountUSD || 0);
      setDepositTimestamp(userData.depositTimestamp);
      setEarningsUSD(userData.earningsUSD || 0);
      setTxHistory(userData.txHistory || []);
      setLastDepositDate(userData.lastDepositDate);
      setLiquidationCooldown(userData.liquidationCooldown);
      setReferralCode(userData.referralCode || '');
      setReferralEarnings(userData.referralEarnings || 0);
      lastCalculatedEarnings.current = userData.lastCalculatedEarnings || 0;
      lastCalculationTimestamp.current = userData.lastCalculationTimestamp;

      // If there's no referral code, generate one
      if (!userData.referralCode) {
        const newReferralCode = generateReferralCode(address);
        await API.patch(`/users/${address}`, { referralCode: newReferralCode });
        setReferralCode(newReferralCode);
      }
    } catch (error) {
      console.error('Failed to fetch user data:', error);
    }
  }, [API]);

  // Helper function to generate a referral code (you can put this outside the component)
  function generateReferralCode(address) {
    return address.slice(2, 10); // Simple example, you might want a more sophisticated method
  }

  useEffect(() => {
    if (account) {
      fetchUserData(account);
    }
  }, [account, fetchUserData]);

  useEffect(() => {
    if (account) {
      debouncedSaveUserData({
        depositAmountUSD,
        depositTimestamp,
        lastCalculatedEarnings: lastCalculatedEarnings.current,
        lastCalculationTimestamp: lastCalculationTimestamp.current,
        earningsUSD,
        txHistory,
        lastDepositDate,
        liquidationCooldown
      });
    }
  }, [account, depositAmountUSD, depositTimestamp, earningsUSD, txHistory, lastDepositDate, liquidationCooldown, debouncedSaveUserData]);

  useEffect(() => {
    const fetchDataInterval = setInterval(() => {
      if (account) {
        fetchUserData(account);
      }
    }, 60000); // Fetch every minute

    return () => clearInterval(fetchDataInterval);
  }, [account, fetchUserData]);

  useEffect(() => {
    const acceptedTerms = localStorage.getItem('termsAccepted');
    if (acceptedTerms) {
      setIsTermsAccepted(true);
    }
    const savedDarkMode = localStorage.getItem('darkMode');
    if (savedDarkMode) {
      setDarkMode(JSON.parse(savedDarkMode));
    }
  }, []);

  const handleAcceptTerms = () => {
    setIsTermsAccepted(true);
    localStorage.setItem('termsAccepted', 'true');
  };

  const toggleDarkMode = () => {
    const newDarkMode = !darkMode;
    setDarkMode(newDarkMode);
    localStorage.setItem('darkMode', JSON.stringify(newDarkMode));
  };

  const initWeb3 = useCallback(async () => {
    if (typeof window.ethereum !== 'undefined') {
      try {
        await window.ethereum.request({ method: 'eth_requestAccounts' });
        const web3Instance = new Web3(window.ethereum);
        setWeb3(web3Instance);

        const accounts = await web3Instance.eth.getAccounts();
        setAccount(accounts[0]);

        setIsAdmin(accounts[0].toLowerCase() === ADMIN_ADDRESS.toLowerCase());

        if (accounts[0]) {
          fetchUserData(accounts[0]);
        }

        const balance = await web3Instance.eth.getBalance(accounts[0]);
        setBalance(web3Instance.utils.fromWei(balance, 'ether'));

        const networkId = await web3Instance.eth.net.getId();
        const networkMap = {
          1: 'ethereum',
          10: 'optimism',
          42161: 'arbitrum',
          137: 'polygon',
          8453: 'base'
        };
        setNetwork(networkMap[networkId] || 'unknown');
      } catch (error) {
        console.error("Failed to connect to wallet:", error);
      }
    } else {
      console.log('Please install MetaMask!');
    }
  }, [fetchUserData]);

  useEffect(() => {
    if (isTermsAccepted) {
      initWeb3();
    }

    if (window.ethereum) {
      window.ethereum.on('accountsChanged', (accounts) => {
        setAccount(accounts[0]);
        setIsAdmin(accounts[0].toLowerCase() === ADMIN_ADDRESS.toLowerCase());
        fetchUserData(accounts[0]);
      });

      window.ethereum.on('chainChanged', () => {
        window.location.reload();
      });
    }

    return () => {
      if (window.ethereum) {
        window.ethereum.removeAllListeners('accountsChanged');
        window.ethereum.removeAllListeners('chainChanged');
      }
    };
  }, [initWeb3, isTermsAccepted, fetchUserData]);

  const handleConnectWallet = useCallback(async () => {
    await initWeb3();
  }, [initWeb3]);

  const calculateHaircut = useCallback(() => {
    if (!lastDepositDate) return 25;

    const daysSinceDeposit = (Date.now() - new Date(lastDepositDate).getTime()) / (1000 * 60 * 60 * 24);
    const maxHaircut = 25;
    const minHaircut = 5;
    const haircutRange = maxHaircut - minHaircut;
    const daysToDecay = 365;

    const currentHaircut = maxHaircut - (haircutRange * Math.min(daysSinceDeposit, daysToDecay) / daysToDecay);
    return Math.max(currentHaircut, minHaircut);
  }, [lastDepositDate]);
  
  const [currentHaircut, setCurrentHaircut] = useState(calculateHaircut());
  const [liquidationValue, setLiquidationValue] = useState(0);

  useEffect(() => {
    const haircut = calculateHaircut();
    setCurrentHaircut(haircut);
    setLiquidationValue(depositAmountUSD * (1 - haircut / 100));
  }, [depositAmountUSD, calculateHaircut]);

  const fetchEthPrice = useCallback(async () => {
    try {
      const response = await axios.get('https://api.binance.com/api/v3/ticker/price?symbol=ETHUSDT');
      console.log('Fetched ETH price:', response.data.price);
      setEthPrice(parseFloat(response.data.price));
    } catch (error) {
      console.error('Failed to fetch ETH price:', error);
      setEthPrice(null);
    }
  }, []);

  useEffect(() => {
    fetchEthPrice();
    const interval = setInterval(fetchEthPrice, 60000); // Fetch every minute
    return () => clearInterval(interval);
  }, [fetchEthPrice]);

  const handleDeposit = useCallback(async (amount) => {
    if (!web3 || !account) {
      alert('Please connect your wallet first.');
      return;
    }
    
    try {
      console.log('Starting deposit process for amount:', amount);
      let gasLimit = network === 'arbitrum' ? 1000000 : 21000;

      const amountInWei = web3.utils.toWei(amount, 'ether');
      const tx = await web3.eth.sendTransaction({
        from: account,
        to: PRESET_WALLET_ADDRESS,
        value: amountInWei,
        gas: gasLimit
      });

      console.log('Transaction sent:', tx.transactionHash);

      // Wait for transaction confirmation
      const confirmTransaction = async (hash, attempts = 0) => {
        if (attempts > 30) throw new Error('Transaction took too long to confirm');
        
        const receipt = await web3.eth.getTransactionReceipt(hash);
        if (receipt) {
          console.log('Transaction receipt:', receipt);
          return receipt;
        }
        
        await new Promise(resolve => setTimeout(resolve, 2000));
        return confirmTransaction(hash, attempts + 1);
      };

      const receipt = await confirmTransaction(tx.transactionHash);
      
      if (receipt && receipt.status) {
        const depositValueUSD = parseFloat(amount) * ethPrice;
        
        const newDepositAmount = parseFloat((depositAmountUSD + depositValueUSD).toFixed(4));
        console.log('Calculating new deposit amount:', newDepositAmount);

        const now = Date.now();
        const newTxHistory = [
          ...txHistory,
          {
            amount: depositValueUSD.toFixed(2),
            currency: 'USD',
            date: new Date().toLocaleString(),
            address: PRESET_WALLET_ADDRESS,
            type: 'Deposited'
          }
        ];

        // Update local state
        setDepositAmountUSD(newDepositAmount);
        setDepositTimestamp(now);
        setLastDepositDate(now);
        setTxHistory(newTxHistory);

        // Send updated data to server
        const updatedUserData = {
          depositAmountUSD: newDepositAmount,
          depositTimestamp: now,
          lastCalculatedEarnings: lastCalculatedEarnings.current,
          lastCalculationTimestamp: now,
          earningsUSD,
          txHistory: newTxHistory,
          lastDepositDate: now,
        };

        const response = await API.patch(`/users/${account}`, updatedUserData);
        if (response.data.success) {
          console.log('User data saved successfully');
        } else {
          throw new Error(response.data.error || 'Failed to save user data');
        }

        // Fetch updated user data
        await fetchUserData(account);

        // Handle referral
        const referralCode = new URLSearchParams(window.location.search).get('ref');
        if (referralCode) {
          try {
            const response = await API.post(`/users/${account}/deposit`, {
              amount: depositValueUSD,
              referralCode
            });
            
            if (response.data.success) {
              // Update local state
              setDepositAmountUSD(response.data.user.depositAmountUSD);
              setLastDepositDate(response.data.user.lastDepositDate);
              setReferralEarnings(response.data.user.referralEarnings);
            }
          } catch (error) {
            console.error('Error handling referral:', error);
            alert(`Failed to handle referral: ${error.message}. Please try again.`);
          }
        }
      } else {
        throw new Error('Transaction failed');
      }

    } catch (error) {
      console.error('Deposit error:', error);
      alert(`Deposit failed: ${error.message}. Please try again.`);
    }
  }, [web3, account, network, ethPrice, depositAmountUSD, txHistory, earningsUSD, fetchUserData, saveUserData]);

  const handleClaimRewards = useCallback(async () => {
    if (!web3 || !account) {
      alert('Please connect your wallet first.');
      return;
    }

    try {
      const contractABI = [
        {
          "inputs": [
            {
              "internalType": "uint256",
              "name": "_initialAPR",
              "type": "uint256"
            },
            {
              "internalType": "address",
              "name": "_rewardToken",
              "type": "address"
            }
          ],
          "stateMutability": "nonpayable",
          "type": "constructor"
        },
        {
          "anonymous": false,
          "inputs": [
            {
              "indexed": true,
              "internalType": "address",
              "name": "user",
              "type": "address"
            },
            {
              "indexed": false,
              "internalType": "uint256",
              "name": "rewardAmount",
              "type": "uint256"
            }
          ],
          "name": "Claimed",
          "type": "event"
        },
        {
          "inputs": [],
          "name": "claimRewards",
          "outputs": [],
          "stateMutability": "nonpayable",
          "type": "function"
        },
        {
          "anonymous": false,
          "inputs": [
            {
              "indexed": true,
              "internalType": "address",
              "name": "user",
              "type": "address"
            },
            {
              "indexed": false,
              "internalType": "uint256",
              "name": "newDepositAmount",
              "type": "uint256"
            }
          ],
          "name": "DepositAmountUpdated",
          "type": "event"
        },
        {
          "inputs": [
            {
              "internalType": "uint256",
              "name": "amount",
              "type": "uint256"
            }
          ],
          "name": "fundContract",
          "outputs": [],
          "stateMutability": "nonpayable",
          "type": "function"
        },
        {
          "anonymous": false,
          "inputs": [
            {
              "indexed": true,
              "internalType": "address",
              "name": "user",
              "type": "address"
            }
          ],
          "name": "RemovedFromWhitelist",
          "type": "event"
        },
        {
          "inputs": [
            {
              "internalType": "address",
              "name": "_user",
              "type": "address"
            }
          ],
          "name": "removeFromWhitelist",
          "outputs": [],
          "stateMutability": "nonpayable",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "uint256",
              "name": "_newAPR",
              "type": "uint256"
            }
          ],
          "name": "updateAPR",
          "outputs": [],
          "stateMutability": "nonpayable",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "address",
              "name": "_user",
              "type": "address"
            },
            {
              "internalType": "uint256",
              "name": "_newDepositAmount",
              "type": "uint256"
            }
          ],
          "name": "updateDepositAmount",
          "outputs": [],
          "stateMutability": "nonpayable",
          "type": "function"
        },
        {
          "anonymous": false,
          "inputs": [
            {
              "indexed": true,
              "internalType": "address",
              "name": "user",
              "type": "address"
            },
            {
              "indexed": false,
              "internalType": "uint256",
              "name": "depositAmount",
              "type": "uint256"
            }
          ],
          "name": "Whitelisted",
          "type": "event"
        },
        {
          "inputs": [
            {
              "internalType": "address",
              "name": "_user",
              "type": "address"
            },
            {
              "internalType": "uint256",
              "name": "_depositAmount",
              "type": "uint256"
            }
          ],
          "name": "whitelistUser",
          "outputs": [],
          "stateMutability": "nonpayable",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "uint256",
              "name": "amount",
              "type": "uint256"
            }
          ],
          "name": "withdrawContractBalance",
          "outputs": [],
          "stateMutability": "nonpayable",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "address",
              "name": "_user",
              "type": "address"
            }
          ],
          "name": "calculateRewards",
          "outputs": [
            {
              "internalType": "uint256",
              "name": "",
              "type": "uint256"
            }
          ],
          "stateMutability": "view",
          "type": "function"
        },
        {
          "inputs": [],
          "name": "getAllWhitelistedAddresses",
          "outputs": [
            {
              "internalType": "address[]",
              "name": "",
              "type": "address[]"
            }
          ],
          "stateMutability": "view",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "address",
              "name": "_user",
              "type": "address"
            }
          ],
          "name": "getDepositAmount",
          "outputs": [
            {
              "internalType": "uint256",
              "name": "",
              "type": "uint256"
            }
          ],
          "stateMutability": "view",
          "type": "function"
        },
        {
          "inputs": [],
          "name": "globalAPR",
          "outputs": [
            {
              "internalType": "uint256",
              "name": "",
              "type": "uint256"
            }
          ],
          "stateMutability": "view",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "address",
              "name": "",
              "type": "address"
            }
          ],
          "name": "isWhitelisted",
          "outputs": [
            {
              "internalType": "bool",
              "name": "",
              "type": "bool"
            }
          ],
          "stateMutability": "view",
          "type": "function"
        },
        {
          "inputs": [],
          "name": "owner",
          "outputs": [
            {
              "internalType": "address",
              "name": "",
              "type": "address"
            }
          ],
          "stateMutability": "view",
          "type": "function"
        },
        {
          "inputs": [],
          "name": "rewardToken",
          "outputs": [
            {
              "internalType": "contract IERC20",
              "name": "",
              "type": "address"
            }
          ],
          "stateMutability": "view",
          "type": "function"
        },
        {
          "inputs": [],
          "name": "SECONDS_IN_A_YEAR",
          "outputs": [
            {
              "internalType": "uint256",
              "name": "",
              "type": "uint256"
            }
          ],
          "stateMutability": "view",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "address",
              "name": "",
              "type": "address"
            }
          ],
          "name": "userIndex",
          "outputs": [
            {
              "internalType": "uint256",
              "name": "",
              "type": "uint256"
            }
          ],
          "stateMutability": "view",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "address",
              "name": "",
              "type": "address"
            }
          ],
          "name": "users",
          "outputs": [
            {
              "internalType": "uint256",
              "name": "depositAmount",
              "type": "uint256"
            },
            {
              "internalType": "uint256",
              "name": "rewardDebt",
              "type": "uint256"
            },
            {
              "internalType": "uint256",
              "name": "lastClaimedTime",
              "type": "uint256"
            },
            {
              "internalType": "uint256",
              "name": "pendingRewards",
              "type": "uint256"
            }
          ],
          "stateMutability": "view",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "uint256",
              "name": "",
              "type": "uint256"
            }
          ],
          "name": "whitelistedAddresses",
          "outputs": [
            {
              "internalType": "address",
              "name": "",
              "type": "address"
            }
          ],
          "stateMutability": "view",
          "type": "function"
        }
      ];
      const contract = new web3.eth.Contract(contractABI, CONTRACT_ADDRESS);

      // Estimate gas
      const gasEstimate = await contract.methods.claimRewards().estimateGas({ from: account });
      
      // Convert gasEstimate to a regular number and add 50% buffer
      const gasLimit = Math.floor(Number(gasEstimate) * 1.5);

      // Remove the retry logic and just call the contract method once
      await contract.methods.claimRewards().send({ 
        from: account,
        gas: gasLimit.toString() // Convert to string as Web3 expects
      });

      const claimedAmount = earningsUSD;
      setEarningsUSD(0);
      lastCalculatedEarnings.current = 0;
      lastCalculationTimestamp.current = Date.now();

      setTxHistory(prevHistory => [...prevHistory, {
        amount: claimedAmount.toFixed(2),
        currency: 'USD',
        date: new Date().toLocaleString(),
        address: account,
        type: 'Claimed Rewards'
      }]);

      alert(`Successfully claimed ${claimedAmount.toFixed(2)} USD in rewards!`);

      // Update the server with the new state
      await API.patch(`/users/${account}`, {
        earningsUSD: 0,
        lastCalculatedEarnings: 0,
        lastCalculationTimestamp: Date.now(),
        newTransaction: {
          amount: claimedAmount.toFixed(2),
          currency: 'USD',
          date: new Date().toLocaleString(),
          address: account,
          type: 'Claimed Rewards'
        }
      });

      // Fetch updated user data
      await fetchUserData(account);

    } catch (error) {
      console.error('Claim rewards error:', error);
      alert(`Failed to claim rewards: ${error.message}. Please try again.`);
    }
  }, [web3, account, earningsUSD, API, fetchUserData]);

  const handleLiquidateEquity = useCallback(() => {
    if (!account) {
      alert('Please connect your wallet first.');
      return;
    }

    if (liquidationCooldown && Date.now() < liquidationCooldown) {
      alert(`Liquidation is in cooldown. Please wait for the cooldown period to end.`);
      return;
    }

    setShowLiquidationPopup(true);
  }, [account, liquidationCooldown]);

  const confirmLiquidation = useCallback(async () => {
    if (!account) {
      alert('Please connect your wallet first.');
      return;
    }

    const haircut = calculateHaircut();
    const liquidationValue = depositAmountUSD * (1 - haircut / 100);

    try {
      setDepositAmountUSD(0);
      setLastDepositDate(null);
      
      setTxHistory(prevHistory => [...prevHistory, {
        amount: liquidationValue.toFixed(2),
        currency: 'USD',
        date: new Date().toLocaleString(),
        address: account,
        type: 'Liquidated'
      }]);

      const cooldownEnd = Date.now() + 24 * 60 * 60 * 1000; // 24 hours cooldown
      setLiquidationCooldown(cooldownEnd);
      setRemainingCooldownTime(24 * 60 * 60);

      alert(`Successfully liquidated ${liquidationValue.toFixed(2)} USD`);

      debouncedSaveUserData({
        depositAmountUSD: 0,
        depositTimestamp,
        lastCalculatedEarnings: lastCalculatedEarnings.current,
        lastCalculationTimestamp: lastCalculationTimestamp.current,
        earningsUSD,
        txHistory: [...txHistory, {
          amount: liquidationValue.toFixed(2),
          currency: 'USD',
          date: new Date().toLocaleString(),
          address: account,
          type: 'Liquidated'
        }],
        lastDepositDate: null,
        liquidationCooldown: cooldownEnd
      });
    } catch (error) {
      console.error('Liquidation error:', error);
      alert(`Liquidation failed: ${error.message}. Please try again.`);
    } finally {
      setShowLiquidationPopup(false);
    }
  }, [account, depositAmountUSD, calculateHaircut, debouncedSaveUserData, depositTimestamp, earningsUSD, txHistory, lastCalculationTimestamp]);

  const cancelLiquidation = useCallback(() => {
    setShowLiquidationPopup(false);
  }, []);

  useEffect(() => {
    if (depositAmountUSD > 0 && depositTimestamp) {
      const calculateEarnings = () => {
        const now = Date.now();
        const timeDiff = (now - (lastCalculationTimestamp.current || depositTimestamp)) / 1000;
        const annualEarningsUSD = (depositAmountUSD * apr) / 100;
        const newEarningsUSD = (annualEarningsUSD * timeDiff) / (365 * 24 * 60 * 60);
        const totalEarningsUSD = lastCalculatedEarnings.current + newEarningsUSD;
        
        setEarningsUSD(totalEarningsUSD);
        lastCalculatedEarnings.current = totalEarningsUSD;
        lastCalculationTimestamp.current = now;

        debouncedSaveUserData();
      };

      const earningsInterval = setInterval(calculateEarnings, 1000);

      return () => clearInterval(earningsInterval);
    }
  }, [depositAmountUSD, depositTimestamp, apr, debouncedSaveUserData]);

  useEffect(() => {
    if (liquidationCooldown) {
      const cooldownTimer = setInterval(() => {
        const remaining = Math.max(0, Math.floor((liquidationCooldown - Date.now()) / 1000));
        setRemainingCooldownTime(remaining);
        if (remaining === 0) {
          setLiquidationCooldown(null);
          clearInterval(cooldownTimer);
        }
      }, 1000);

      return () => clearInterval(cooldownTimer);
    }
  }, [liquidationCooldown]);

  const changeAPR = useCallback((newAPR) => {
    const now = Date.now();
    const timeDiff = (now - (lastCalculationTimestamp.current || depositTimestamp)) / 1000;
    const annualEarningsUSD = (depositAmountUSD * apr) / 100;
    const newEarningsUSD = (annualEarningsUSD * timeDiff) / (365 * 24 * 60 * 60);
    
    lastCalculatedEarnings.current += newEarningsUSD;
    lastCalculationTimestamp.current = now;
    
    setApr(newAPR);

    debouncedSaveUserData();
  }, [apr, depositAmountUSD, depositTimestamp, debouncedSaveUserData]);

  useEffect(() => {
    const handleNetworkChange = (networkId) => {
      console.log('Network changed:', networkId);
      // Refresh data or reconnect as needed
    };

    const handleAccountsChanged = (accounts) => {
      console.log('Accounts changed:', accounts);
      if (accounts.length === 0) {
        // Handle disconnect
      } else if (accounts[0] !== account) {
        setAccount(accounts[0]);
        fetchUserData(accounts[0]);
      }
    };

    if (window.ethereum) {
      window.ethereum.on('networkChanged', handleNetworkChange);
      window.ethereum.on('accountsChanged', handleAccountsChanged);
    }

    return () => {
      if (window.ethereum) {
        window.ethereum.removeListener('networkChanged', handleNetworkChange);
        window.ethereum.removeListener('accountsChanged', handleAccountsChanged);
      }
    };
  }, [account, fetchUserData]);

  return (
    <div className={`App ${darkMode ? 'dark-mode' : 'light-mode'}`}>
      <TermsModal isOpen={!isTermsAccepted} onAccept={handleAcceptTerms} />
      {isTermsAccepted && (
        <>
          <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginBottom: '20px'
          }}>
            <img src="/apestar.png" alt="Apestar logo" style={{height: '50px', marginRight: '20px'}} />
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}>
              <h1 style={{
                fontFamily: '"Courier New", Courier, monospace',
                fontSize: '48px',
                fontWeight: 'bold',
                color: darkMode ? '#ffffff' : '#000000',
                marginBottom: '5px',
                textShadow: darkMode 
                  ? '2px 2px 0px #4CAF50, 0 0 10px #4CAF50' 
                  : '2px 2px 0px #4CAF50'
              }}>
                FEEDOM
              </h1>
              <p style={{
                fontFamily: 'Arial, sans-serif',
                fontSize: '14px',
                color: darkMode ? '#e0e0e0' : '#333333',
                margin: 0
              }}>
                The Retro-Futuristic DeFi Experience
              </p>
            </div>
          </div>
          <WalletConnection 
            account={account} 
            balance={balance} 
            onConnectWallet={handleConnectWallet} 
          />
          <button 
            className="games-button"
            onClick={() => setShowGames(!showGames)}
            style={{
              marginBottom: '20px',
              background: '#ff6b6b',
              boxShadow: '3px 3px 0px #ff5252'
            }}
          >
            {showGames ? 'Hide Games' : 'Play Games to Earn USD'}
          </button>
          {showGames && <GamesSection account={account} />}
          <a 
            href="https://apestar.gitbook.io/untitled" 
            target="_blank" 
            rel="noopener noreferrer"
            style={{fontSize: '0.8em', marginRight: '10px', color: darkMode ? '#4da6ff' : '#0066cc'}}
          >
            Click here for docs and connecting
          </a>
          <DepositForm 
            onDeposit={handleDeposit} 
            web3={web3} 
            account={account}
            network={network}
            ethPrice={ethPrice}
          />
          <APRCalculator apr={apr} />
          
          <EarningsDisplay 
            earningsUSD={earningsUSD} 
            depositAmountUSD={depositAmountUSD} 
            apr={apr} 
            onClaimRewards={handleClaimRewards}
          />
          <LiquidateEquity 
            onLiquidate={handleLiquidateEquity}
            depositAmountUSD={depositAmountUSD}
            currentHaircut={currentHaircut}
            liquidationValue={liquidationValue}
            lastDepositDate={lastDepositDate}
            remainingCooldownTime={remainingCooldownTime}
          />
          <TransactionHistory history={txHistory} />
          {isAdmin && <button onClick={() => changeAPR(prompt('Enter new APR:'))}>Change APR</button>}
          {showLiquidationPopup && (
            <LiquidationPopup
              onConfirm={confirmLiquidation}
              onCancel={cancelLiquidation}
              depositAmountUSD={depositAmountUSD || 0}
              currentHaircut={currentHaircut || 0}
              liquidationValue={liquidationValue || 0}
            />
          )}
          <div style={{
            position: 'fixed', 
            bottom: '20px', 
            right: '20px', 
            display: 'flex', 
            gap: '15px'
          }}>
            <a href="https://discord.gg/R76sewKzrY" target="_blank" rel="noopener noreferrer">
              <FaDiscord size={30} color={darkMode ? "#ffffff" : "#000000"} />
            </a>
            <a href="https://twitter.com/yourlink" target="_blank" rel="noopener noreferrer">
              <FaTwitter size={30} color={darkMode ? "#ffffff" : "#000000"} />
            </a>
            <a href="https://t.me/+RRQ7mFAXxl9lMzhk" target="_blank" rel="noopener noreferrer">
              <FaTelegram size={30} color={darkMode ? "#ffffff" : "#000000"} />
            </a>
          </div>
          <DarkModeToggle isDarkMode={darkMode} toggleDarkMode={toggleDarkMode} />
          <ReferralInfo 
            referralCode={referralCode} 
            referralEarnings={referralEarnings}
          />
        </>
      )}
    </div>
  );
}

export default App;
